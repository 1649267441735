export const PROFIT_DATA = [
    ['Fri Apr 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 12.6529],
    ['Thu Apr 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 23.7806],
    ['Wed Apr 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 29.311],
    ['Tue Apr 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 53.4802],
    ['Mon Apr 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 40.0464],
    ['Sun Apr 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 42.032],
    ['Sat Apr 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 43.4961],
    ['Fri Apr 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 35.2007],
    ['Thu Apr 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 45.1618],
    ['Wed Apr 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 59.7521],
    ['Tue Apr 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 43.809],
    ['Mon Apr 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 49.6002],
    ['Sun Apr 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 45.4054],
    ['Sat Apr 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 37.158],
    ['Fri Apr 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 47.5781],
    ['Thu Apr 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 44.8986],
    ['Wed Apr 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 50.4578],
    ['Tue Apr 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 38.6233],
    ['Mon Apr 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 25.7298],
    ['Sun Apr 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 24.3152],
    ['Sat Apr 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 38.1446],
    ['Fri Apr 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 40.1976],
    ['Thu Apr 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 36.1865],
    ['Wed Apr 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 38.6336],
    ['Tue Apr 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 54.0711],
    ['Mon Apr 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 44.2596],
    ['Sun Mar 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 52.0228],
    ['Sat Mar 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 111.072],
    ['Fri Mar 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 107.527],
    ['Thu Mar 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 100.208],
    ['Wed Mar 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 110.371],
    ['Tue Mar 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 101.683],
    ['Mon Mar 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 79.2361],
    ['Sun Mar 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 67.1648],
    ['Sat Mar 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 76.7275],
    ['Fri Mar 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 78.0105],
    ['Thu Mar 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 67.7816],
    ['Wed Mar 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 121.393],
    ['Tue Mar 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 82.959],
    ['Mon Mar 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 124.962],
    ['Sun Mar 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 86.4135],
    ['Sat Mar 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 87.8418],
    ['Fri Mar 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 102.842],
    ['Thu Mar 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 94.4258],
    ['Wed Mar 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 86.7159],
    ['Tue Mar 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.1601],
    ['Mon Mar 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 105.782],
    ['Sun Mar 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 108.162],
    ['Sat Mar 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 84.4461],
    ['Fri Mar 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.9057],
    ['Thu Mar 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 93.5918],
    ['Wed Mar 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 95.4283],
    ['Tue Mar 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 90.3857],
    ['Mon Mar 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 89.7509],
    ['Sun Mar 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 90.6652],
    ['Sat Mar 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.824],
    ['Fri Mar 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 88.3932],
    ['Thu Feb 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 80.971],
    ['Wed Feb 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.3025],
    ['Tue Feb 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 90.6898],
    ['Mon Feb 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.2045],
    ['Sun Feb 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 89.9253],
    ['Sat Feb 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 96.8287],
    ['Fri Feb 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 100.068],
    ['Thu Feb 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 108.556],
    ['Wed Feb 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 129.892],
    ['Tue Feb 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 144.05],
    ['Mon Feb 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 140.594],
    ['Sun Feb 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 128.199],
    ['Sat Feb 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 78.8199],
    ['Fri Feb 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 88.5506],
    ['Thu Feb 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 98.7171],
    ['Wed Feb 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 88.6917],
    ['Tue Feb 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 99.6891],
    ['Mon Feb 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 78.773],
    ['Sun Feb 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.6757],
    ['Sat Feb 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 82.0462],
    ['Fri Feb 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 95.8255],
    ['Thu Feb 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 89.0758],
    ['Wed Feb 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 92.1629],
    ['Tue Feb 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 96.248],
    ['Mon Feb 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 106.267],
    ['Sun Feb 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 100.332],
    ['Sat Feb 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.1331],
    ['Fri Feb 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 94.0147],
    ['Thu Feb 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 106.885],
    ['Wed Jan 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 101.147],
    ['Tue Jan 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 101.06],
    ['Mon Jan 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 78.0269],
    ['Sun Jan 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 83.5122],
    ['Sat Jan 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 81.0007],
    ['Fri Jan 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 57.5953],
    ['Thu Jan 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 71.177],
    ['Wed Jan 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 72.8159],
    ['Tue Jan 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 79.7055],
    ['Mon Jan 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 94.3667],
    ['Sun Jan 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 100.142],
    ['Sat Jan 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 92.4689],
    ['Fri Jan 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 84.6875],
    ['Thu Jan 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 84.8698],
    ['Wed Jan 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 71.8496],
    ['Tue Jan 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 87.0222],
    ['Mon Jan 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 89.3554],
    ['Sun Jan 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 85.009],
    ['Sat Jan 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 93.7518],
    ['Fri Jan 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 92.5168],
    ['Thu Jan 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 93.0052],
    ['Wed Jan 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 105.466],
    ['Tue Jan 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 128.07],
    ['Mon Jan 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 134.12],
    ['Sun Jan 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 142.897],
    ['Sat Jan 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 134.099],
    ['Fri Jan 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 92.6538],
    ['Thu Jan 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 116.46],
    ['Wed Jan 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 97.1246],
    ['Tue Jan 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 115.588],
    ['Mon Jan 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 101.129],
    ['Sun Dec 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 94.7226],
    ['Sat Dec 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 102.839],
    ['Fri Dec 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 93.2067],
    ['Thu Dec 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 96.585],
    ['Wed Dec 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 84.3451],
    ['Tue Dec 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 76.0765],
    ['Mon Dec 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 75.8915],
    ['Sun Dec 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 73.7027],
    ['Sat Dec 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 83.3229],
    ['Fri Dec 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 83.1408],
    ['Thu Dec 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 78.665],
    ['Wed Dec 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 90.9596],
    ['Tue Dec 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.6662],
    ['Mon Dec 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 99.3836],
    ['Sun Dec 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 89.6082],
    ['Sat Dec 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 92.1746],
    ['Fri Dec 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 99.3211],
    ['Thu Dec 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 103.551],
    ['Wed Dec 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 104.011],
    ['Tue Dec 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 46.4465],
    ['Mon Dec 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 68.2832],
    ['Sun Dec 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 53.8399],
    ['Sat Dec 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 40.9052],
    ['Fri Dec 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 55.5641],
    ['Thu Dec 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 57.4696],
    ['Wed Dec 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 54.2812],
    ['Tue Dec 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 55.3157],
    ['Mon Dec 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.4025],
    ['Sun Dec 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 60.2944],
    ['Sat Dec 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 64.4063],
    ['Fri Dec 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 60.2388],
    ['Thu Nov 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.9121],
    ['Wed Nov 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 54.9538],
    ['Tue Nov 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.8152],
    ['Mon Nov 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.0098],
    ['Sun Nov 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 59.7323],
    ['Sat Nov 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 54.9267],
    ['Fri Nov 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 51.9198],
    ['Thu Nov 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 40.2041],
    ['Wed Nov 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 35.7571],
    ['Tue Nov 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 0.5],
    ['Mon Nov 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 8.27513],
    ['Sun Nov 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 25.4094],
    ['Sat Nov 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 18.1517],
    ['Fri Nov 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 8.58075],
    ['Thu Nov 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 21.0546],
    ['Wed Nov 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 13.3802],
    ['Tue Nov 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 3.13048],
    ['Mon Nov 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 12.5092],
    ['Sun Nov 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 9.10253],
    ['Sat Nov 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 7.12982],
    ['Fri Nov 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 18.6335],
    ['Thu Nov 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 23.4452],
    ['Wed Nov 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 21.6543],
    ['Tue Nov 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 25.0886],
    ['Mon Nov 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 19.0403],
    ['Sun Nov 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 16.9507],
    ['Sat Nov 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 18.5154],
    ['Fri Nov 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 16.4935],
    ['Thu Nov 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 9.6516],
    ['Wed Nov 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 10.91],
    ['Tue Oct 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 11.3126],
    ['Mon Oct 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 19.5317],
    ['Sun Oct 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 13.9399],
    ['Sat Oct 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 6.74993],
    ['Fri Oct 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 4.48713],
    ['Thu Oct 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 48.3827],
    ['Wed Oct 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 34.1689],
    ['Tue Oct 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 55.0676],
    ['Mon Oct 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.0898],
    ['Sun Oct 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.8657],
    ['Sat Oct 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 59.0283],
    ['Fri Oct 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 51.6663],
    ['Thu Oct 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.2869],
    ['Wed Oct 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 63.6602],
    ['Tue Oct 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 54.855],
    ['Mon Oct 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.7415],
    ['Sun Oct 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 54.6346],
    ['Sat Oct 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 56.5847],
    ['Fri Oct 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.7465],
    ['Thu Oct 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 51.8899],
    ['Wed Oct 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 51.6847],
    ['Tue Oct 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.4025],
    ['Mon Oct 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 65.0485],
    ['Sun Oct 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.1549],
    ['Sat Oct 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 85.1428],
    ['Fri Oct 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 87.6851],
    ['Thu Oct 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.5282],
    ['Wed Oct 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 80.3287],
    ['Tue Oct 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 31.2086],
    ['Mon Oct 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.6386],
    ['Sun Oct 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 34.453],
    ['Sat Sep 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 50.1238],
    ['Fri Sep 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.6948],
    ['Thu Sep 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 37.05],
    ['Wed Sep 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 40.6305],
    ['Tue Sep 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 27.1787],
    ['Mon Sep 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 29.6867],
    ['Sun Sep 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 39.6415],
    ['Sat Sep 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 39.1358],
    ['Fri Sep 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.2111],
    ['Thu Sep 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 41.3908],
    ['Wed Sep 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 46.7656],
    ['Tue Sep 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.4361],
    ['Mon Sep 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 41.6277],
    ['Sun Sep 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 40.573],
    ['Sat Sep 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.1668],
    ['Fri Sep 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.8631],
    ['Thu Sep 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 45.9596],
    ['Wed Sep 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 39.5319],
    ['Tue Sep 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 45.5162],
    ['Mon Sep 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.1266],
    ['Sun Sep 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 41.6822],
    ['Sat Sep 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 33.8264],
    ['Fri Sep 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 27.1508],
    ['Thu Sep 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 29.0869],
    ['Wed Sep 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 23.097],
    ['Tue Sep 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 30.3133],
    ['Mon Sep 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 26.4354],
    ['Sun Sep 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 21.9698],
    ['Sat Sep 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 34.611],
    ['Fri Sep 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 34.2347],
    ['Thu Aug 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 40.4165],
    ['Wed Aug 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 42.9939],
    ['Tue Aug 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.159],
    ['Mon Aug 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 48.3967],
    ['Sun Aug 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.881],
    ['Sat Aug 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.7113],
    ['Fri Aug 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 44.6081],
    ['Thu Aug 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 37.5007],
    ['Wed Aug 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 35.2579],
    ['Tue Aug 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 41.5596],
    ['Mon Aug 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 41.8354],
    ['Sun Aug 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 44.8634],
    ['Sat Aug 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 44.0733],
    ['Fri Aug 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 41.4974],
    ['Thu Aug 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 41.8669],
    ['Wed Aug 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 32.739],
    ['Tue Aug 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 37.8767],
    ['Mon Aug 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 29.1969],
    ['Sun Aug 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 42.9641],
    ['Sat Aug 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 42.9456],
    ['Fri Aug 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 54.9738],
    ['Thu Aug 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 40.4577],
    ['Wed Aug 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 45.573],
    ['Tue Aug 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 38.2439],
    ['Mon Aug 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 42.6123],
    ['Sun Aug 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 52.57],
    ['Sat Aug 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 51.6887],
    ['Fri Aug 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.9666],
    ['Thu Aug 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 57.8443],
    ['Wed Aug 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 59.3931],
    ['Tue Aug 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 64.0132],
    ['Mon Jul 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 53.9698],
    ['Sun Jul 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 59.1318],
    ['Sat Jul 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 50.91],
    ['Fri Jul 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 49.4482],
    ['Thu Jul 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 53.7815],
    ['Wed Jul 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 56.299],
    ['Tue Jul 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.2866],
    ['Mon Jul 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 60.6209],
    ['Sun Jul 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 59.6088],
    ['Sat Jul 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 65.8839],
    ['Fri Jul 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 76.8217],
    ['Thu Jul 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 83.8493],
    ['Wed Jul 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 76.6758],
    ['Tue Jul 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 75.051],
    ['Mon Jul 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 79.9078],
    ['Sun Jul 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 98.7077],
    ['Sat Jul 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 74.4384],
    ['Fri Jul 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 75.8958],
    ['Thu Jul 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 71.3802],
    ['Wed Jul 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 76.1092],
    ['Tue Jul 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 75.9165],
    ['Mon Jul 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 85.6779],
    ['Sun Jul 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 84.4012],
    ['Sat Jul 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 78.6591],
    ['Fri Jul 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 71.6124],
    ['Thu Jul 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 85.1717],
    ['Wed Jul 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 97.9795],
    ['Tue Jul 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.4275],
    ['Mon Jul 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.276],
    ['Sun Jul 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 95.1451],
    ['Sat Jul 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 93.2365],
    ['Fri Jun 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 99.9379],
    ['Thu Jun 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 115.183],
    ['Wed Jun 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 100.944],
    ['Tue Jun 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 97.0762],
    ['Mon Jun 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 107.211],
    ['Sun Jun 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 100.383],
    ['Sat Jun 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 96.4033],
    ['Fri Jun 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 99.324],
    ['Thu Jun 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 68.1971],
    ['Wed Jun 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 68.1848],
    ['Tue Jun 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 72.425],
    ['Mon Jun 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 75.0249],
    ['Sun Jun 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 69.9748],
    ['Sat Jun 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 74.9364],
    ['Fri Jun 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 97.7972],
    ['Thu Jun 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 96.0701],
    ['Wed Jun 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 97.8202],
    ['Tue Jun 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 97.6986],
    ['Mon Jun 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 118.953],
    ['Sun Jun 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 116.481],
    ['Sat Jun 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 102.763],
    ['Fri Jun 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 111.116],
    ['Thu Jun 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 84.1323],
    ['Wed Jun 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 80.702],
    ['Tue Jun 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 80.4017],
    ['Mon Jun 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.8431],
    ['Sun Jun 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 88.124],
    ['Sat Jun 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 89.9668],
    ['Fri Jun 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 83.1676],
    ['Thu Jun 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 87.6548],
    ['Wed May 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 89.9796],
    ['Tue May 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 104.414],
    ['Mon May 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 96.203],
];
export const MANAGER_ASSETS_DATA = [
    ['Fri Apr 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 2681.17],
    [
        'Thu Apr 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        845.1980000000001,
    ],
    ['Wed Apr 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 626.279],
    ['Tue Apr 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 517.479],
    ['Mon Apr 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 41.9934],
    ['Sun Apr 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 306.279],
    ['Sat Apr 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 267.215],
    ['Fri Apr 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 238.412],
    ['Thu Apr 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 401.61],
    ['Wed Apr 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 205.642],
    ['Tue Apr 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 81.3956],
    ['Mon Apr 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 232.257],
    [
        'Sun Apr 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        118.32499999999999,
    ],
    [
        'Sat Apr 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        200.85000000000002,
    ],
    [
        'Fri Apr 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        363.10299999999995,
    ],
    ['Thu Apr 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 158.105],
    ['Wed Apr 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 210.82],
    ['Tue Apr 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 101.453],
    [
        'Mon Apr 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        334.27500000000003,
    ],
    ['Sun Apr 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 587.933],
    ['Sat Apr 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 615.762],
    [
        'Fri Apr 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        343.69399999999996,
    ],
    [
        'Thu Apr 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        303.30400000000003,
    ],
    ['Wed Apr 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 382.216],
    ['Tue Apr 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 334.074],
    ['Mon Apr 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 30.3673],
    [
        'Sun Mar 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        223.39200000000002,
    ],
    ['Sat Mar 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 70.6639],
    ['Fri Mar 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1091.02],
    ['Thu Mar 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1021.28],
    ['Wed Mar 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 877.291],
    ['Tue Mar 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1077.22],
    ['Mon Mar 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 906.307],
    [
        'Sun Mar 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        464.70799999999997,
    ],
    ['Sat Mar 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 227.227],
    ['Fri Mar 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 415.356],
    ['Thu Mar 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 440.596],
    ['Wed Mar 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 239.362],
    [
        'Tue Mar 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        1294.0700000000002,
    ],
    ['Mon Mar 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 537.95],
    ['Sun Mar 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1364.28],
    ['Sat Mar 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 605.911],
    ['Fri Mar 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 634.01],
    [
        'Thu Mar 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        929.1039999999999,
    ],
    ['Wed Mar 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 763.539],
    ['Tue Mar 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 611.86],
    ['Mon Mar 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 699.293],
    ['Sun Mar 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 986.944],
    ['Sat Mar 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1033.77],
    ['Fri Mar 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 567.206],
    ['Thu Mar 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 713.96],
    ['Wed Mar 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 747.131],
    ['Tue Mar 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 783.26],
    ['Mon Mar 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 684.058],
    ['Sun Mar 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 671.568],
    ['Sat Mar 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 689.556],
    ['Fri Mar 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 712.353],
    ['Thu Feb 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 644.859],
    [
        'Wed Feb 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        498.84000000000003,
    ],
    ['Tue Feb 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 702.093],
    ['Mon Feb 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 690.039],
    [
        'Sun Feb 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        700.1659999999999,
    ],
    ['Sat Feb 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 675],
    [
        'Fri Feb 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        810.8119999999999,
    ],
    [
        'Thu Feb 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        874.5310000000001,
    ],
    ['Wed Feb 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1041.52],
    ['Tue Feb 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1461.27],
    ['Mon Feb 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1739.81],
    [
        'Sun Feb 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        1671.8100000000002,
    ],
    ['Sat Feb 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1427.97],
    [
        'Fri Feb 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        456.52099999999996,
    ],
    ['Thu Feb 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 647.955],
    ['Wed Feb 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 847.963],
    ['Tue Feb 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 650.731],
    ['Mon Feb 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 867.085],
    ['Sun Feb 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 455.597],
    ['Sat Feb 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 709.436],
    [
        'Fri Feb 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        519.9929999999999,
    ],
    ['Thu Feb 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 791.075],
    ['Wed Feb 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 658.288],
    ['Tue Feb 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 719.02],
    ['Mon Feb 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 799.388],
    ['Sun Feb 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 996.502],
    ['Sat Feb 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 879.74],
    ['Fri Feb 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 698.76],
    ['Thu Feb 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 755.452],
    ['Wed Jan 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1008.65],
    ['Tue Jan 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 498.843],
    ['Mon Jan 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 500.565],
    ['Sun Jan 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 953.693],
    ['Sat Jan 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 845.78],
    ['Fri Jan 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 895.188],
    ['Thu Jan 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1355.65],
    ['Wed Jan 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1088.45],
    ['Tue Jan 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1056.21],
    ['Mon Jan 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 920.67],
    ['Sun Jan 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 632.237],
    ['Sat Jan 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 518.625],
    ['Fri Jan 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 27.7339],
    ['Thu Jan 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 125.352],
    ['Wed Jan 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 121.764],
    [
        'Tue Jan 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        377.91499999999996,
    ],
    ['Mon Jan 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 79.4196],
    ['Sun Jan 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 33.5181],
    [
        'Sat Jan 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        119.02699999999999,
    ],
    ['Fri Jan 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 52.9721],
    ['Thu Jan 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 28.6754],
    [
        'Wed Jan 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        38.285399999999996,
    ],
    ['Tue Jan 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 283.433],
    ['Mon Jan 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 728.125],
    [
        'Sun Jan 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)',
        847.1389999999999,
    ],
    ['Sat Jan 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1019.81],
    ['Fri Jan 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 846.734],
    ['Thu Jan 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 31.3715],
    ['Wed Jan 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 499.71],
    ['Tue Jan 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 119.327],
    ['Mon Jan 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 482.567],
    ['Sun Dec 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 198.104],
    ['Sat Dec 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 72.0724],
    ['Fri Dec 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 231.756],
    ['Thu Dec 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 42.2478],
    ['Wed Dec 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1503.32],
    ['Tue Dec 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1959.83],
    ['Mon Dec 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1797.16],
    ['Sun Dec 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1793.52],
    ['Sat Dec 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1750.46],
    ['Fri Dec 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1939.72],
    ['Thu Dec 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1936.14],
    ['Wed Dec 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1848.09],
    ['Tue Dec 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2089.96],
    ['Mon Dec 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2005.5],
    ['Sun Dec 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2255.69],
    ['Sat Dec 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2063.37],
    ['Fri Dec 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2113.86],
    ['Thu Dec 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2245.35],
    ['Wed Dec 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2355.11],
    ['Tue Dec 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 2349.95],
    ['Mon Dec 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1214.24],
    ['Sun Dec 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1643.84],
    ['Sat Dec 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1359.7],
    ['Fri Dec 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1105.23],
    ['Thu Dec 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1393.62],
    [
        'Wed Dec 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1431.1000000000001,
    ],
    [
        'Tue Dec 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1368.3799999999999,
    ],
    ['Mon Dec 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1388.73],
    ['Sun Dec 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1449.46],
    ['Sat Dec 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1486.68],
    [
        'Fri Dec 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1567.5700000000002,
    ],
    ['Thu Nov 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1485.58],
    ['Wed Nov 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1518.5],
    [
        'Tue Nov 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1381.6100000000001,
    ],
    ['Mon Nov 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.9643],
    [
        'Sun Nov 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        125.81099999999999,
    ],
    ['Sat Nov 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 81.0059],
    ['Fri Nov 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 13.5367],
    [
        'Thu Nov 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        72.69059999999999,
    ],
    ['Wed Nov 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 303.178],
    ['Tue Nov 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 390.664],
    ['Mon Nov 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1084.28],
    ['Sun Nov 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 931.322],
    ['Sat Nov 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 594.237],
    ['Fri Nov 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 737.019],
    [
        'Thu Nov 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        925.3100000000001,
    ],
    ['Wed Nov 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 679.909],
    ['Tue Nov 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 830.89],
    ['Mon Nov 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1032.53],
    ['Sun Nov 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 848.025],
    ['Sat Nov 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 915.045],
    ['Fri Nov 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 953.854],
    [
        'Thu Nov 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        727.5400000000001,
    ],
    ['Wed Nov 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 632.879],
    ['Tue Nov 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 668.111],
    ['Mon Nov 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 600.547],
    ['Sun Nov 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 719.538],
    ['Sat Nov 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 760.646],
    ['Fri Nov 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 729.863],
    ['Thu Nov 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 769.64],
    ['Wed Nov 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 904.243],
    ['Tue Oct 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 879.486],
    ['Mon Oct 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 871.566],
    ['Sun Oct 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 709.869],
    [
        'Sat Oct 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        819.8789999999999,
    ],
    ['Fri Oct 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 961.328],
    ['Thu Oct 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1005.84],
    ['Wed Oct 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 142.278],
    ['Tue Oct 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 421.909],
    ['Mon Oct 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 10.7643],
    ['Sun Oct 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 48.6923],
    [
        'Sat Oct 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        63.957699999999996,
    ],
    ['Fri Oct 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 67.1559],
    ['Thu Oct 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.6793],
    ['Wed Oct 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 131.262],
    ['Tue Oct 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 158.28],
    [
        'Mon Oct 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        14.946299999999999,
    ],
    ['Sun Oct 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 140.206],
    ['Sat Oct 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 19.2818],
    ['Fri Oct 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 19.0823],
    ['Thu Oct 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.6124],
    [
        'Wed Oct 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        73.28020000000001,
    ],
    ['Tue Oct 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.3169],
    ['Mon Oct 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 133.536],
    ['Sun Oct 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 185.592],
    ['Sat Oct 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 128.667],
    ['Fri Oct 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 580.911],
    ['Thu Oct 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 630.928],
    [
        'Wed Oct 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        431.10799999999995,
    ],
    [
        'Tue Oct 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        486.20399999999995,
    ],
    ['Mon Oct 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 480.147],
    ['Sun Oct 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 235.609],
    ['Sat Sep 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 416.319],
    ['Fri Sep 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 108.024],
    ['Thu Sep 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 234.503],
    [
        'Wed Sep 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        365.22799999999995,
    ],
    ['Tue Sep 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 294.788],
    ['Mon Sep 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 559.429],
    ['Sun Sep 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 510.088],
    ['Sat Sep 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 314.245],
    ['Fri Sep 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 324.193],
    ['Thu Sep 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 165.326],
    ['Wed Sep 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 279.83],
    [
        'Tue Sep 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        174.09099999999998,
    ],
    ['Mon Sep 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 160.901],
    ['Sun Sep 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 275.17],
    ['Sat Sep 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 295.919],
    [
        'Fri Sep 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        244.89100000000002,
    ],
    ['Thu Sep 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 231.192],
    ['Wed Sep 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 189.947],
    ['Tue Sep 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 316.401],
    ['Mon Sep 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 198.671],
    ['Sun Sep 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 108.436],
    ['Sat Sep 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 274.097],
    ['Fri Sep 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 428.648],
    ['Thu Sep 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 559.977],
    ['Wed Sep 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 521.889],
    ['Tue Sep 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 639.728],
    [
        'Mon Sep 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        497.76000000000005,
    ],
    [
        'Sun Sep 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        574.0509999999999,
    ],
    ['Sat Sep 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 661.904],
    ['Fri Sep 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 413.212],
    ['Thu Aug 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 420.614],
    [
        'Wed Aug 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        298.99899999999997,
    ],
    ['Tue Aug 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 248.293],
    [
        'Mon Aug 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        245.04500000000002,
    ],
    ['Sun Aug 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 142.002],
    ['Sat Aug 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 230.841],
    ['Fri Aug 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 234.179],
    [
        'Thu Aug 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        216.53500000000003,
    ],
    ['Wed Aug 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 356.361],
    [
        'Tue Aug 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        400.48499999999996,
    ],
    ['Mon Aug 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 276.51],
    [
        'Sun Aug 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        271.08299999999997,
    ],
    [
        'Sat Aug 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        211.51299999999998,
    ],
    ['Fri Aug 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 227.056],
    ['Thu Aug 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 277.734],
    [
        'Wed Aug 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        270.46299999999997,
    ],
    ['Tue Aug 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 450.039],
    ['Mon Aug 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 348.964],
    ['Sun Aug 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 519.723],
    ['Sat Aug 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 248.879],
    ['Fri Aug 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 249.243],
    ['Thu Aug 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 12.6102],
    ['Wed Aug 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 298.188],
    [
        'Tue Aug 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        197.55400000000003,
    ],
    ['Mon Aug 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 341.74],
    [
        'Sun Aug 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        255.79999999999998,
    ],
    [
        'Sat Aug 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        59.900099999999995,
    ],
    ['Fri Aug 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.237],
    [
        'Thu Aug 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        150.46200000000002,
    ],
    ['Wed Aug 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.8635],
    ['Tue Aug 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 74.333],
    [
        'Mon Jul 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        165.22500000000002,
    ],
    [
        'Sun Jul 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        32.360099999999996,
    ],
    ['Sat Jul 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 69.1924],
    ['Fri Jul 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 92.5563],
    [
        'Thu Jul 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        121.31500000000001,
    ],
    ['Wed Jul 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 36.0657],
    [
        'Tue Jul 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        13.462499999999999,
    ],
    ['Mon Jul 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 111.583],
    ['Sun Jul 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 98.4885],
    ['Sat Jul 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 78.5771],
    ['Fri Jul 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 202.029],
    ['Thu Jul 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 417.209],
    [
        'Wed Jul 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        555.4639999999999,
    ],
    ['Tue Jul 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 414.34],
    ['Mon Jul 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 382.375],
    ['Sun Jul 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 477.923],
    [
        'Sat Jul 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        847.7769999999999,
    ],
    ['Fri Jul 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 370.322],
    ['Thu Jul 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 398.995],
    ['Wed Jul 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 310.157],
    ['Tue Jul 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 403.193],
    ['Mon Jul 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 399.4],
    [
        'Sun Jul 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        591.4390000000001,
    ],
    ['Sat Jul 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 566.322],
    ['Fri Jul 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 453.357],
    ['Thu Jul 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 314.725],
    ['Wed Jul 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 581.48],
    ['Tue Jul 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 833.451],
    ['Mon Jul 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 606.187],
    ['Sun Jul 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 603.207],
    [
        'Sat Jul 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        777.6909999999999,
    ],
    ['Fri Jun 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 740.141],
    [
        'Thu Jun 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        871.9799999999999,
    ],
    [
        'Wed Jun 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        1171.8899999999999,
    ],
    ['Tue Jun 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 891.769],
    ['Mon Jun 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 815.681],
    ['Sun Jun 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1015.07],
    [
        'Sat Jun 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        880.7299999999999,
    ],
    ['Fri Jun 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 802.442],
    ['Thu Jun 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 859.902],
    ['Wed Jun 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 247.535],
    [
        'Tue Jun 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        247.29399999999998,
    ],
    ['Mon Jun 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 330.712],
    [
        'Sun Jun 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        381.86100000000005,
    ],
    [
        'Sat Jun 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        282.50800000000004,
    ],
    ['Fri Jun 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 380.12],
    ['Thu Jun 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 829.865],
    [
        'Wed Jun 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        795.8889999999999,
    ],
    ['Tue Jun 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 830.317],
    [
        'Mon Jun 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        827.9250000000001,
    ],
    ['Sun Jun 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1246.07],
    ['Sat Jun 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1197.44],
    ['Fri Jun 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 927.559],
    ['Thu Jun 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1091.88],
    ['Wed Jun 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 561.033],
    ['Tue Jun 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 493.547],
    [
        'Mon Jun 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        487.64000000000004,
    ],
    ['Sun Jun 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 122.532],
    ['Sat Jun 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 639.563],
    [
        'Fri Jun 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        675.8159999999999,
    ],
    ['Thu Jun 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 542.053],
    ['Wed May 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 630.331],
    [
        'Tue May 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        676.0680000000001,
    ],
    [
        'Mon May 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)',
        960.0360000000001,
    ],
];
export const MARGIN_DATA = [
    ['Fri Apr 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 17],
    ['Thu Apr 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 14.7148],
    ['Wed Apr 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 27.2728],
    ['Tue Apr 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 33.514],
    ['Mon Apr 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 60.7895],
    ['Sun Apr 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 45.6292],
    ['Sat Apr 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 47.87],
    ['Fri Apr 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 49.5223],
    ['Thu Apr 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 40.1607],
    ['Wed Apr 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 51.4021],
    ['Tue Apr 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 67.8676],
    ['Mon Apr 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 49.8753],
    ['Sun Apr 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 56.4109],
    ['Sat Apr 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 51.677],
    ['Fri Apr 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 42.3696],
    ['Thu Apr 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 54.1289],
    ['Wed Apr 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 51.105],
    ['Tue Apr 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 57.3787],
    ['Mon Apr 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 44.0232],
    ['Sun Apr 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 29.4725],
    ['Sat Apr 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 27.8761],
    ['Fri Apr 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 43.4829],
    ['Thu Apr 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 45.7998],
    ['Wed Apr 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 41.2731],
    ['Tue Apr 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 44.0347],
    ['Mon Apr 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 61.4565],
    ['Sun Mar 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 50.3839],
    ['Sat Mar 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 59.1449],
    ['Fri Mar 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 125.783],
    ['Thu Mar 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 121.783],
    ['Wed Mar 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 113.523],
    ['Tue Mar 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 124.992],
    ['Mon Mar 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 115.187],
    ['Sun Mar 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 89.8557],
    ['Sat Mar 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 76.233],
    ['Fri Mar 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 87.0247],
    ['Thu Mar 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 88.4726],
    ['Wed Mar 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 76.9291],
    ['Tue Mar 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 137.431],
    ['Mon Mar 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 94.0572],
    ['Sun Mar 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 141.458],
    ['Sat Mar 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 97.9557],
    ['Fri Mar 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 99.5675],
    ['Thu Mar 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 116.495],
    ['Wed Mar 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 106.998],
    ['Tue Mar 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 98.2969],
    ['Mon Mar 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 103.312],
    ['Sun Mar 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 119.813],
    ['Sat Mar 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 122.499],
    ['Fri Mar 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 95.7354],
    ['Thu Mar 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 104.154],
    ['Wed Mar 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 106.057],
    ['Tue Mar 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 108.129],
    ['Mon Mar 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 102.438],
    ['Sun Mar 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 101.722],
    ['Sat Mar 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 102.754],
    ['Fri Mar 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 104.062],
    ['Thu Feb 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 100.19],
    ['Wed Feb 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.8137],
    ['Tue Feb 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 103.473],
    ['Mon Feb 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 102.782],
    ['Sun Feb 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 103.362],
    ['Sat Feb 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 101.919],
    ['Fri Feb 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 109.71],
    ['Thu Feb 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 113.365],
    ['Wed Feb 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 122.944],
    ['Tue Feb 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 147.022],
    ['Mon Feb 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 163],
    ['Sun Feb 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 159.099],
    ['Sat Feb 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 145.112],
    ['Fri Feb 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 89.3861],
    ['Thu Feb 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 100.367],
    ['Wed Feb 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 111.841],
    ['Tue Feb 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 100.527],
    ['Mon Feb 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 112.938],
    ['Sun Feb 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 89.3331],
    ['Sat Feb 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 103.894],
    ['Fri Feb 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 93.0271],
    ['Thu Feb 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 108.577],
    ['Wed Feb 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 100.96],
    ['Tue Feb 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 104.444],
    ['Mon Feb 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 109.054],
    ['Sun Feb 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 120.361],
    ['Sat Feb 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 113.663],
    ['Fri Feb 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 103.282],
    ['Thu Feb 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 106.534],
    ['Wed Jan 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 121.058],
    ['Tue Jan 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 114.583],
    ['Mon Jan 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 114.484],
    ['Sun Jan 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 88.4912],
    ['Sat Jan 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 94.6815],
    ['Fri Jan 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 91.8472],
    ['Thu Jan 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 65.4335],
    ['Wed Jan 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 80.7609],
    ['Tue Jan 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 82.6104],
    ['Mon Jan 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 90.3855],
    ['Sun Jan 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 106.931],
    ['Sat Jan 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 113.448],
    ['Fri Jan 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 144.789],
    ['Thu Jan 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 136.008],
    ['Wed Jan 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 136.214],
    ['Tue Jan 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 121.52],
    ['Mon Jan 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 138.643],
    ['Sun Jan 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 141.276],
    ['Sat Jan 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 136.371],
    ['Fri Jan 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 146.237],
    ['Thu Jan 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 144.843],
    ['Wed Jan 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 145.395],
    ['Tue Jan 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 159.457],
    ['Mon Jan 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 154.966],
    ['Sun Jan 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 144.998],
    ['Sat Jan 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 171.864],
    ['Fri Jan 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 150.043],
    ['Thu Jan 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 170.88],
    ['Wed Jan 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 154.562],
    ['Tue Jan 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 147.333],
    ['Mon Jan 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 156.493],
    ['Sun Dec 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 145.622],
    ['Sat Dec 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 149.434],
    ['Fri Dec 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 95.6214],
    ['Thu Dec 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.2901],
    ['Wed Dec 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.0813],
    ['Tue Dec 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 83.6112],
    ['Mon Dec 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 94.4678],
    ['Sun Dec 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 94.2623],
    ['Sat Dec 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 89.2113],
    ['Fri Dec 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 103.086],
    ['Thu Dec 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 98.2409],
    ['Wed Dec 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 112.593],
    ['Tue Dec 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 101.561],
    ['Mon Dec 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 104.457],
    ['Sun Dec 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 112.522],
    ['Sat Dec 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 117.296],
    ['Fri Dec 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 117.815],
    ['Thu Dec 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 52.8518],
    ['Wed Dec 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.4952],
    ['Tue Dec 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 54.1955],
    ['Mon Dec 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 39.5983],
    ['Sun Dec 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 56.1413],
    ['Sat Dec 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 65.2917],
    ['Fri Dec 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.6935],
    ['Thu Dec 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.8609],
    ['Wed Dec 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 66.3445],
    ['Tue Dec 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 68.4795],
    ['Mon Dec 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 73.1199],
    ['Sun Dec 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 68.4169],
    ['Sat Dec 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 70.3052],
    ['Fri Dec 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.4526],
    ['Thu Nov 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 66.8103],
    ['Wed Nov 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 70.4154],
    ['Tue Nov 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 67.8452],
    ['Mon Nov 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.4219],
    ['Sun Nov 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 59.0286],
    ['Sat Nov 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 45.8071],
    ['Fri Nov 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 40.7886],
    ['Thu Nov 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1],
    ['Wed Nov 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 9.77444],
    ['Tue Nov 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 29.1109],
    ['Mon Nov 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 20.9204],
    ['Sun Nov 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 10.1193],
    ['Sat Nov 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 24.1964],
    ['Fri Nov 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 15.5356],
    ['Thu Nov 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 3.96857],
    ['Wed Nov 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 14.5527],
    ['Tue Nov 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 10.7082],
    ['Mon Nov 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 8.48193],
    ['Sun Nov 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 21.4642],
    ['Sat Nov 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 26.8942],
    ['Fri Nov 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 24.8732],
    ['Thu Nov 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 28.7489],
    ['Wed Nov 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 21.9232],
    ['Tue Nov 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 19.565],
    ['Mon Nov 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 21.3309],
    ['Sun Nov 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 19.0491],
    ['Sat Nov 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 11.3278],
    ['Fri Nov 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 12.7479],
    ['Thu Nov 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 13.2023],
    ['Wed Nov 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 22.4778],
    ['Tue Oct 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 16.1672],
    ['Mon Oct 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 8.05322],
    ['Sun Oct 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 5.49959],
    ['Sat Oct 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 55.0369],
    ['Fri Oct 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 38.9962],
    ['Thu Oct 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.581],
    ['Wed Oct 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 65.9916],
    ['Tue Oct 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 66.8673],
    ['Mon Oct 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 67.0507],
    ['Sun Oct 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.7425],
    ['Sat Oct 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 70.7281],
    ['Fri Oct 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 72.2779],
    ['Thu Oct 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.3411],
    ['Wed Oct 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 71.2412],
    ['Tue Oct 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.0924],
    ['Mon Oct 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 64.2931],
    ['Sun Oct 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 66.7327],
    ['Sat Oct 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.9948],
    ['Fri Oct 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.7633],
    ['Thu Oct 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 70.8586],
    ['Wed Oct 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 73.8446],
    ['Tue Oct 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 70.5792],
    ['Mon Oct 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 96.5216],
    ['Sun Oct 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 99.3907],
    ['Sat Oct 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 87.9283],
    ['Fri Oct 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 91.0889],
    ['Thu Oct 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 35.6555],
    ['Wed Oct 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 49.6831],
    ['Tue Oct 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 39.3169],
    ['Mon Oct 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 57.0018],
    ['Sun Oct 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 49.7465],
    ['Sat Sep 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 42.2477],
    ['Fri Sep 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 46.2883],
    ['Thu Sep 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 31.1076],
    ['Wed Sep 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 33.938],
    ['Tue Sep 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 45.1722],
    ['Mon Sep 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 44.6015],
    ['Sun Sep 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 53.7148],
    ['Sat Sep 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.1464],
    ['Fri Sep 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 53.212],
    ['Thu Sep 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 53.9686],
    ['Wed Sep 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.4137],
    ['Tue Sep 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 46.2234],
    ['Mon Sep 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 49.1506],
    ['Sun Sep 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 49.9364],
    ['Sat Sep 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 52.3024],
    ['Fri Sep 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 45.0485],
    ['Thu Sep 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 51.802],
    ['Wed Sep 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 69.4187],
    ['Tue Sep 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.4752],
    ['Mon Sep 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 38.6097],
    ['Sun Sep 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 31.0761],
    ['Sat Sep 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 33.261],
    ['Fri Sep 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 26.5013],
    ['Thu Sep 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 34.6451],
    ['Wed Sep 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 30.2688],
    ['Tue Sep 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 25.2293],
    ['Mon Sep 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 39.4951],
    ['Sun Sep 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 39.0705],
    ['Sat Sep 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 46.0468],
    ['Fri Sep 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 48.9554],
    ['Thu Aug 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 49.1418],
    ['Wed Aug 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 55.0527],
    ['Tue Aug 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 49.9566],
    ['Mon Aug 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 49.7651],
    ['Sun Aug 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 50.7772],
    ['Sat Aug 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 42.7563],
    ['Fri Aug 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 40.2252],
    ['Thu Aug 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.3368],
    ['Wed Aug 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.6482],
    ['Tue Aug 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 51.0653],
    ['Mon Aug 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 50.1737],
    ['Sun Aug 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.2666],
    ['Sat Aug 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 47.6837],
    ['Fri Aug 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 37.3826],
    ['Thu Aug 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.1806],
    ['Wed Aug 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 33.3852],
    ['Tue Aug 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 48.9218],
    ['Mon Aug 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 48.901],
    ['Sun Aug 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 62.4751],
    ['Sat Aug 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 46.0933],
    ['Fri Aug 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 51.866],
    ['Thu Aug 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 43.595],
    ['Wed Aug 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 48.5248],
    ['Tue Aug 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 59.7624],
    ['Mon Aug 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 58.7678],
    ['Sun Aug 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 54.5674],
    ['Sat Aug 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 65.7146],
    ['Fri Aug 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 67.4624],
    ['Thu Aug 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 72.6763],
    ['Wed Aug 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.3421],
    ['Tue Aug 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 67.1676],
    ['Mon Jul 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 57.8891],
    ['Sun Jul 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 56.2394],
    ['Sat Jul 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 61.1296],
    ['Fri Jul 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 63.9707],
    ['Thu Jul 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 69.5993],
    ['Wed Jul 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 68.8481],
    ['Tue Jul 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 67.7059],
    ['Mon Jul 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 74.7875],
    ['Sun Jul 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 87.1311],
    ['Sat Jul 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 95.0618],
    ['Fri Jul 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.9665],
    ['Thu Jul 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 85.1328],
    ['Wed Jul 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 90.6138],
    ['Tue Jul 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 111.83],
    ['Mon Jul 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 84.4414],
    ['Sun Jul 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.0862],
    ['Sat Jul 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 80.9902],
    ['Fri Jul 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.327],
    ['Thu Jul 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 86.1095],
    ['Wed Jul 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 97.1255],
    ['Tue Jul 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 95.6847],
    ['Mon Jul 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 89.2047],
    ['Sun Jul 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 81.2522],
    ['Sat Jul 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 96.5542],
    ['Fri Jul 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 111.008],
    ['Thu Jul 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 97.9715],
    ['Wed Jul 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 97.8005],
    ['Tue Jul 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 107.81],
    ['Mon Jul 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 105.656],
    ['Sun Jul 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 113.218],
    ['Sat Jul 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 130.423],
    ['Fri Jun 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 114.353],
    ['Thu Jun 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 109.989],
    ['Wed Jun 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 121.427],
    ['Tue Jun 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 113.72],
    ['Mon Jun 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 109.229],
    ['Sun Jun 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 112.525],
    ['Sat Jun 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.3979],
    ['Fri Jun 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77.3841],
    ['Thu Jun 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 82.1693],
    ['Wed Jun 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 85.1033],
    ['Tue Jun 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 79.4041],
    ['Mon Jun 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 85.0035],
    ['Sun Jun 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 110.802],
    ['Sat Jun 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 108.853],
    ['Fri Jun 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 110.828],
    ['Thu Jun 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 110.691],
    ['Wed Jun 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 134.678],
    ['Tue Jun 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 131.888],
    ['Mon Jun 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 116.407],
    ['Sun Jun 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 125.833],
    ['Sat Jun 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 95.3813],
    ['Fri Jun 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 91.5101],
    ['Thu Jun 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 91.1712],
    ['Wed Jun 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 70.2273],
    ['Tue Jun 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 99.8861],
    ['Mon Jun 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 101.966],
    ['Sun Jun 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 94.2925],
    ['Sat Jun 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 99.3565],
    ['Fri Jun 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 101.98],
    ['Thu Jun 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 118.27],
    ['Wed May 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 109.003],
];
export const COPIERS_DATA = [
    ['Fri Apr 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 119],
    ['Thu Apr 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 98],
    ['Wed Apr 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 189],
    ['Tue Apr 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 231],
    ['Mon Apr 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 420],
    ['Sun Apr 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 315],
    ['Sat Apr 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 329],
    ['Fri Apr 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Thu Apr 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 280],
    ['Wed Apr 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 357],
    ['Tue Apr 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 469],
    ['Mon Apr 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Sun Apr 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 392],
    ['Sat Apr 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 357],
    ['Fri Apr 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 294],
    ['Thu Apr 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 378],
    ['Wed Apr 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 357],
    ['Tue Apr 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 399],
    ['Mon Apr 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 308],
    ['Sun Apr 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 203],
    ['Sat Apr 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 189],
    ['Fri Apr 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 301],
    ['Thu Apr 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 315],
    ['Wed Apr 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 287],
    ['Tue Apr 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 308],
    ['Mon Apr 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 427],
    ['Sun Mar 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 350],
    ['Sat Mar 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 413],
    ['Fri Mar 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 875],
    ['Thu Mar 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 847],
    ['Wed Mar 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 791],
    ['Tue Mar 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 868],
    ['Mon Mar 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 805],
    ['Sun Mar 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 623],
    ['Sat Mar 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 532],
    ['Fri Mar 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 609],
    ['Thu Mar 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 616],
    ['Wed Mar 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 532],
    ['Tue Mar 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 959],
    ['Mon Mar 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 658],
    ['Sun Mar 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 987],
    ['Sat Mar 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 679],
    ['Fri Mar 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 693],
    ['Thu Mar 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 812],
    ['Wed Mar 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 742],
    ['Tue Mar 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 686],
    ['Mon Mar 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 721],
    ['Sun Mar 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 833],
    ['Sat Mar 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 854],
    ['Fri Mar 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 665],
    ['Thu Mar 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 728],
    ['Wed Mar 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 742],
    ['Tue Mar 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 756],
    ['Mon Mar 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 714],
    ['Sun Mar 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 707],
    ['Sat Mar 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 714],
    ['Fri Mar 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 728],
    ['Thu Feb 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 700],
    ['Wed Feb 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 637],
    ['Tue Feb 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 721],
    ['Mon Feb 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 714],
    ['Sun Feb 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 721],
    ['Sat Feb 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 707],
    ['Fri Feb 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 763],
    ['Thu Feb 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 791],
    ['Wed Feb 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 854],
    ['Tue Feb 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1029],
    ['Mon Feb 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1141],
    ['Sun Feb 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1113],
    ['Sat Feb 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1015],
    ['Fri Feb 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 623],
    ['Thu Feb 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 700],
    ['Wed Feb 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 777],
    ['Tue Feb 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 700],
    ['Mon Feb 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 784],
    ['Sun Feb 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 623],
    ['Sat Feb 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 721],
    ['Fri Feb 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 651],
    ['Thu Feb 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 756],
    ['Wed Feb 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 700],
    ['Tue Feb 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 728],
    ['Mon Feb 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 763],
    ['Sun Feb 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 840],
    ['Sat Feb 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 791],
    ['Fri Feb 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 721],
    ['Thu Feb 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 742],
    ['Wed Jan 31 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 847],
    ['Tue Jan 30 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 798],
    ['Mon Jan 29 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 798],
    ['Sun Jan 28 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 616],
    ['Sat Jan 27 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 658],
    ['Fri Jan 26 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 637],
    ['Thu Jan 25 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 455],
    ['Wed Jan 24 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 560],
    ['Tue Jan 23 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 574],
    ['Mon Jan 22 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 630],
    ['Sun Jan 21 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 742],
    ['Sat Jan 20 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 791],
    ['Fri Jan 19 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1008],
    ['Thu Jan 18 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 952],
    ['Wed Jan 17 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 952],
    ['Tue Jan 16 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 847],
    ['Mon Jan 15 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 966],
    ['Sun Jan 14 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 987],
    ['Sat Jan 13 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 952],
    ['Fri Jan 12 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1022],
    ['Thu Jan 11 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1008],
    ['Wed Jan 10 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1015],
    ['Tue Jan 09 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1113],
    ['Mon Jan 08 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1078],
    ['Sun Jan 07 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1008],
    ['Sat Jan 06 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1197],
    ['Fri Jan 05 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1050],
    ['Thu Jan 04 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1190],
    ['Wed Jan 03 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1078],
    ['Tue Jan 02 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1029],
    ['Mon Jan 01 2024 08:39:29 GMT+0300 (Москва, стандартное время)', 1092],
    ['Sun Dec 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1015],
    ['Sat Dec 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 1043],
    ['Fri Dec 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 665],
    ['Thu Dec 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 602],
    ['Wed Dec 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 602],
    ['Tue Dec 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 581],
    ['Mon Dec 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 658],
    ['Sun Dec 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 658],
    ['Sat Dec 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 623],
    ['Fri Dec 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 721],
    ['Thu Dec 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 686],
    ['Wed Dec 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 784],
    ['Tue Dec 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 707],
    ['Mon Dec 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 728],
    ['Sun Dec 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 784],
    ['Sat Dec 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 819],
    ['Fri Dec 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 819],
    ['Thu Dec 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 364],
    ['Wed Dec 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 539],
    ['Tue Dec 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 378],
    ['Mon Dec 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 273],
    ['Sun Dec 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 392],
    ['Sat Dec 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 455],
    ['Fri Dec 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 427],
    ['Thu Dec 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 434],
    ['Wed Dec 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 462],
    ['Tue Dec 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 476],
    ['Mon Dec 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 511],
    ['Sun Dec 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 476],
    ['Sat Dec 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 490],
    ['Fri Dec 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 434],
    ['Thu Nov 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 462],
    ['Wed Nov 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 490],
    ['Tue Nov 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 469],
    ['Mon Nov 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 434],
    ['Sun Nov 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 413],
    ['Sat Nov 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 315],
    ['Fri Nov 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 280],
    ['Thu Nov 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 7],
    ['Wed Nov 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 63],
    ['Tue Nov 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 203],
    ['Mon Nov 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 140],
    ['Sun Nov 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 70],
    ['Sat Nov 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 168],
    ['Fri Nov 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 105],
    ['Thu Nov 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 21],
    ['Wed Nov 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 98],
    ['Tue Nov 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 70],
    ['Mon Nov 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 56],
    ['Sun Nov 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 147],
    ['Sat Nov 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 182],
    ['Fri Nov 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 168],
    ['Thu Nov 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 196],
    ['Wed Nov 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 147],
    ['Tue Nov 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 133],
    ['Mon Nov 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 147],
    ['Sun Nov 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 133],
    ['Sat Nov 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 77],
    ['Fri Nov 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 84],
    ['Thu Nov 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 91],
    ['Wed Nov 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 154],
    ['Tue Oct 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 112],
    ['Mon Oct 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 56],
    ['Sun Oct 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 35],
    ['Sat Oct 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 385],
    ['Fri Oct 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 266],
    ['Thu Oct 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 434],
    ['Wed Oct 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 455],
    ['Tue Oct 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 462],
    ['Mon Oct 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 469],
    ['Sun Oct 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 406],
    ['Sat Oct 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 490],
    ['Fri Oct 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 504],
    ['Thu Oct 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 434],
    ['Wed Oct 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 497],
    ['Tue Oct 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 434],
    ['Mon Oct 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 448],
    ['Sun Oct 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 462],
    ['Sat Oct 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 406],
    ['Fri Oct 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 406],
    ['Thu Oct 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 490],
    ['Wed Oct 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 511],
    ['Tue Oct 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 490],
    ['Mon Oct 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 672],
    ['Sun Oct 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 693],
    ['Sat Oct 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 609],
    ['Fri Oct 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 637],
    ['Thu Oct 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 245],
    ['Wed Oct 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Tue Oct 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 273],
    ['Mon Oct 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 399],
    ['Sun Oct 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Sat Sep 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 294],
    ['Fri Sep 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 322],
    ['Thu Sep 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 217],
    ['Wed Sep 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 231],
    ['Tue Sep 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 315],
    ['Mon Sep 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 308],
    ['Sun Sep 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 371],
    ['Sat Sep 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 329],
    ['Fri Sep 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 371],
    ['Thu Sep 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 371],
    ['Wed Sep 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 329],
    ['Tue Sep 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 322],
    ['Mon Sep 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Sun Sep 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Sat Sep 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 364],
    ['Fri Sep 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 315],
    ['Thu Sep 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 357],
    ['Wed Sep 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 483],
    ['Tue Sep 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 329],
    ['Mon Sep 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 266],
    ['Sun Sep 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 217],
    ['Sat Sep 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 231],
    ['Fri Sep 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 182],
    ['Thu Sep 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 238],
    ['Wed Sep 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 210],
    ['Tue Sep 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 175],
    ['Mon Sep 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 273],
    ['Sun Sep 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 273],
    ['Sat Sep 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 322],
    ['Fri Sep 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 336],
    ['Thu Aug 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Wed Aug 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 385],
    ['Tue Aug 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Mon Aug 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 343],
    ['Sun Aug 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 350],
    ['Sat Aug 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 294],
    ['Fri Aug 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 280],
    ['Thu Aug 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 329],
    ['Wed Aug 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 329],
    ['Tue Aug 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 357],
    ['Mon Aug 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 350],
    ['Sun Aug 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 329],
    ['Sat Aug 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 329],
    ['Fri Aug 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 259],
    ['Thu Aug 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 301],
    ['Wed Aug 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 231],
    ['Tue Aug 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 336],
    ['Mon Aug 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 336],
    ['Sun Aug 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 434],
    ['Sat Aug 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 322],
    ['Fri Aug 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 357],
    ['Thu Aug 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 301],
    ['Wed Aug 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 336],
    ['Tue Aug 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 413],
    ['Mon Aug 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 406],
    ['Sun Aug 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 378],
    ['Sat Aug 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 455],
    ['Fri Aug 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 469],
    ['Thu Aug 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 504],
    ['Wed Aug 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 427],
    ['Tue Aug 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 469],
    ['Mon Jul 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 399],
    ['Sun Jul 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 392],
    ['Sat Jul 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 427],
    ['Fri Jul 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 441],
    ['Thu Jul 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 483],
    ['Wed Jul 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 476],
    ['Tue Jul 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 469],
    ['Mon Jul 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 518],
    ['Sun Jul 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 609],
    ['Sat Jul 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 665],
    ['Fri Jul 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 602],
    ['Thu Jul 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 595],
    ['Wed Jul 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 630],
    ['Tue Jul 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 777],
    ['Mon Jul 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 588],
    ['Sun Jul 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 602],
    ['Sat Jul 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 560],
    ['Fri Jul 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 602],
    ['Thu Jul 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 602],
    ['Wed Jul 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 679],
    ['Tue Jul 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 665],
    ['Mon Jul 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 623],
    ['Sun Jul 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 567],
    ['Sat Jul 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 672],
    ['Fri Jul 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 777],
    ['Thu Jul 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 679],
    ['Wed Jul 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 679],
    ['Tue Jul 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 749],
    ['Mon Jul 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 735],
    ['Sun Jul 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 791],
    ['Sat Jul 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 910],
    ['Fri Jun 30 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 798],
    ['Thu Jun 29 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 763],
    ['Wed Jun 28 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 847],
    ['Tue Jun 27 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 791],
    ['Mon Jun 26 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 763],
    ['Sun Jun 25 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 784],
    ['Sat Jun 24 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 539],
    ['Fri Jun 23 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 539],
    ['Thu Jun 22 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 574],
    ['Wed Jun 21 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 595],
    ['Tue Jun 20 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 553],
    ['Mon Jun 19 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 595],
    ['Sun Jun 18 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 770],
    ['Sat Jun 17 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 756],
    ['Fri Jun 16 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 770],
    ['Thu Jun 15 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 770],
    ['Wed Jun 14 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 938],
    ['Tue Jun 13 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 917],
    ['Mon Jun 12 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 812],
    ['Sun Jun 11 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 875],
    ['Sat Jun 10 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 665],
    ['Fri Jun 09 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 637],
    ['Thu Jun 08 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 637],
    ['Wed Jun 07 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 490],
    ['Tue Jun 06 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 693],
    ['Mon Jun 05 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 707],
    ['Sun Jun 04 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 658],
    ['Sat Jun 03 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 693],
    ['Fri Jun 02 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 707],
    ['Thu Jun 01 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 826],
    ['Wed May 31 2023 08:39:29 GMT+0300 (Москва, стандартное время)', 763],
];
